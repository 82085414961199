const animeElement = document.getElementsByClassName('ribbon-anime');
const animeWrapper = document.getElementsByClassName('.ribbon-anime > *');

function searchOpen() {
    setTimeout(() => {
        $('.ribbon-anime').removeClass("ribbon-anime-start");
        $('.ribbon-anime > *').css("opacity","1");
        $('.ribbon-anime').addClass("ribbon-anime-end");
    }, 1000);
}

searchOpen()

var swiper = new Swiper(".fuarlar", {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: ".next-arrow",
      prevEl: ".prev-arrow",
    },
    breakpoints: {
      // when window width is >= 480px
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      }
    }
});

var swiper = new Swiper(".productSlide", {
    loop: true,
    centeredSlides: true,
    initialSlide: 1,
    spaceBetween: 30,
    slidesPerView: 2,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    breakpoints: {
      // when window width is >= 480px
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      }
    }
  });

  const menuOpen = document.getElementsByClassName('menu-trigger');
  const menu = document.getElementsByClassName('header-menu');
  
  $('.menu-trigger').click(function () {
    $('.header-menu').addClass('header-menu--active')
  })

  $('.header-menu-close').click(function () {
    $('.header-menu').removeClass('header-menu--active')
  })

  $('nav a').on('click', function() {

    var scrollAnchor = $(this).attr('data-scroll'),
        scrollPoint = $('section[data-anchor="' + scrollAnchor + '"]').offset().top - 28;

    $('body,html').animate({
        scrollTop: scrollPoint
    }, 500);

    return false;

})


$(window).scroll(function() {
  var windscroll = $(window).scrollTop();
  if (windscroll >= 0) {
      $('nav').addClass('fixed');
      $('.wrapper .section').each(function(i) {
          if ($(this).position().top <= windscroll - 20) {
              $('nav a.active').removeClass('active');
              $('nav a').eq(i).addClass('active');
          }
      });

  } else {

      $('nav').removeClass('fixed');
      $('nav a.active').removeClass('active');
      $('nav a:first').addClass('active');
  }

}).scroll();